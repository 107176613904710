@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  @font-face {
    font-family: 'Poppins';
    src: local('Poppins Thin'), url('../public/fonts/Poppins-Thin.ttf');
    font-weight: 100;
    font-display: swap;
  }
  @font-face {
    font-family: 'Poppins';
    src: local('Poppins ExtraLight'), url('../public/fonts/Poppins-ExtraLight.ttf');
    font-weight: 200;
    font-display: swap;
  }
  @font-face {
    font-family: 'Poppins';
    src: local('Poppins Light'), url('../public/fonts/Poppins-Light.ttf');
    font-weight: 300;
    font-display: swap;
  }
  @font-face {
    font-family: 'Poppins';
    src: local('Poppins Regular'), url('../public/fonts/Poppins-Regular.ttf');
    font-weight: 400;
    font-display: swap;
  }
  @font-face {
    font-family: 'Poppins';
    src: local('Poppins Medium'), url('../public/fonts/Poppins-Medium.ttf');
    font-weight: 500;
    font-display: swap;
  }
  @font-face {
    font-family: 'Poppins';
    src: local('Poppins SemiBold'), url('../public/fonts/Poppins-SemiBold.ttf');
    font-weight: 600;
    font-display: swap;
  }
  @font-face {
    font-family: 'Poppins';
    src: local('Poppins Bold'), url('../public/fonts/Poppins-Bold.ttf');
    font-weight: 700;
    font-display: swap;
  }
  @font-face {
    font-family: 'Poppins';
    src: local('Poppins ExtraBold'), url('../public/fonts/Poppins-ExtraBold.ttf');
    font-weight: 800;
    font-display: swap;
  }
  @font-face {
    font-family: 'Poppins';
    src: local('Poppins Black'), url('../public/fonts/Poppins-Black.ttf');
    font-weight: 900;
    font-display: swap;
  }
  :root {
    --trans-time: 300ms;
    --cyan-v1: #1adbcf;
    --cyan-v2: #05b7ac;
    --cyan-v3: #04827a;
    --amber-v1: #ffb800;
    --amber-v2: #ffe5a3;
    /* --grey-v1: #e0e0e0;
    --grey-v2: #828282; */
    --PhoneInput-color--focus: var(--cyan-v3) !important;
    --PhoneInputCountryFlag-borderColor: var(--cyan-v3) !important;
    --PhoneInputCountrySelectArrow-color: var(--cyan-v3) !important;
    /* stone-200 */
  }
}

html,
body {
  padding: 0;
  margin: 0;
  font-family: 'Poppins';
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox #cd3131*/
  --toastify-font-family: 'Poppins' !important;
}

::-webkit-scrollbar {
  display: none;
}

input {
  outline: none;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

input[type='range'] {
  @apply h-1 w-full cursor-pointer appearance-none rounded-lg disabled:cursor-not-allowed;
}
input[type='range']::-webkit-slider-thumb {
  @apply h-5 w-5 cursor-ew-resize appearance-none rounded-full bg-amber-400 shadow;
}
input[type='range']::-moz-range-thumb {
  @apply h-5 w-5 cursor-ew-resize appearance-none rounded-full bg-amber-400 shadow;
}
input[type='range']::-ms-thumb {
  @apply h-5 w-5 cursor-ew-resize appearance-none rounded-full bg-amber-400 shadow;
}
input[type='range']::-webkit-slider-thumb:hover {
  @apply bg-amber-300;
}
input[type='range']::-moz-range-thumb:hover {
  @apply bg-amber-300;
}
input[type='range']::-ms-thumb:hover {
  @apply bg-amber-300;
}
:not(.duo-sliders) > input[type='range']::-webkit-slider-runnable-track {
  @apply appearance-none border-none bg-transparent shadow-none;
}
:not(.duo-sliders) > input[type='range']::-moz-range-track {
  @apply appearance-none border-none bg-transparent shadow-none;
}
:not(.duo-sliders) > input[type='range']::-ms-track {
  @apply appearance-none border-none bg-transparent shadow-none;
}
.duo-sliders {
  @apply relative bottom-0.5 w-full;
}
.duo-sliders > input[type='range']::-webkit-slider-thumb {
  pointer-events: all;
}
.duo-sliders > input[type='range']::-moz-range-thumb {
  pointer-events: all;
}
.duo-sliders > input[type='range']::-ms-thumb {
  pointer-events: all;
}
.duo-sliders > input[type='range'] {
  @apply pointer-events-none absolute;
}
.duo-sliders .from-slider {
  @apply top-0.5 z-[1] !h-0;
}

/* -------------------- Containers -------------------- */

.main-container {
  @apply flex w-full items-center justify-center px-2 xl:container sm:px-4;
}
.container-white {
  @apply w-full px-2 py-4 xl:container sm:px-4 md:py-6;
}
.container-gray {
  @apply w-full px-2 xl:container sm:px-4;
}
.fluid-gray {
  @apply w-screen bg-stone-100 py-4 md:py-6;
}

.bg-gradient-dark {
  @apply bg-[linear-gradient(91deg,#1F1F1F_7%,#535353_97%)] bg-no-repeat;
}

.card-container {
  @apply overflow-clip rounded-lg bg-white/100 shadow-lg shadow-stone-200;
}

.table-container {
  @apply grid grid-flow-row auto-rows-max divide-y divide-neutral-300 overflow-x-scroll rounded-lg bg-white;
}

.table-row {
  @apply flex items-center py-4 text-center text-xs md:text-sm;
}

.skeleton-wrap {
  @apply flex animate-pulse flex-col;
}
.skeleton-img-wrap {
  @apply flex items-center justify-center bg-stone-200 dark:bg-stone-300;
}
.skeleton-img {
  @apply h-10 w-10 text-neutral-100 dark:text-neutral-200;
}
.skeleton-line {
  @apply rounded-full bg-stone-200 dark:bg-stone-300;
}

/* -------------------- Buttons -------------------- */

@property --cyanFrom {
  syntax: '<color>';
  inherits: false;
  initial-value: #05b7ac;
}
@property --cyanTo {
  syntax: '<color>';
  inherits: false;
  initial-value: #31e4da;
}

.btn-rd-cyan {
  @apply rounded-full bg-cyan-400 bg-[linear-gradient(86deg,var(--cyanFrom)_33%,var(--cyanTo)_105%)] font-semibold capitalize text-black shadow-[1px_3px_6px_0px_#00969666] outline-none;
}
.btn-hover-cyan {
  transition: --cyanFrom var(--trans-time), --cyanTo var(--trans-time), color var(--trans-time), box-shadow var(--trans-time);
  @apply bg-no-repeat;
}
button.btn-hover-cyan:enabled:hover,
div.btn-hover-cyan:has(*[aria-grey=false]):hover /* to support button element with a div wrapper */ {
  --cyanFrom: #009188;
  --cyanTo: #17c1b7;
  @apply bg-[linear-gradient(80deg,var(--cyanFrom)_65%,var(--cyanTo)_112%)] !text-white/100 shadow-[1px_3px_6px_0_#966C0066];
}

@property --amberFrom {
  syntax: '<color>';
  inherits: false;
  initial-value: #ffb800;
}
@property --amberTo {
  syntax: '<color>';
  inherits: false;
  initial-value: #ffe5a3;
}
.btn-rd-amber {
  @apply rounded-full bg-amber-400 bg-[linear-gradient(80deg,var(--amberFrom)_65%,var(--amberTo)_112%)] font-semibold capitalize text-black shadow-[1px_3px_6px_0_#966C0066] outline-none;
}
.btn-hover-amber {
  transition: --amberFrom var(--trans-time), --amberTo var(--trans-time), color var(--trans-time), box-shadow var(--trans-time), outline-color var(--trans-time);
  @apply bg-no-repeat;
}

:is(button.btn-hover-amber:enabled, 
div.btn-hover-amber:has(*[aria-grey=false]), /* to support button element with a div wrapper */
a.btn-hover-amber):hover {
  --amberFrom: #e98c00;
  --amberTo: var(--amber-v2);
  @apply bg-[linear-gradient(80deg,var(--amberFrom)_65%,var(--amberTo)_112%)] text-white/100 shadow-[1px_3px_6px_0_#966C0066];
}

.btn-rd-outline {
  --amberFrom: transparent;
  --amberTo: transparent;
  box-shadow: 0 0 0 2px var(--amber-v1) inset; /* outline not supported in older safari */
  @apply rounded-full bg-[linear-gradient(80deg,var(--amberFrom)_65%,var(--amberTo)_112%)] font-semibold capitalize hover:shadow-transparent;
}

.btn-nav {
  --amberFrom: transparent;
  --amberTo: transparent;
  @apply rounded-full bg-[linear-gradient(80deg,var(--amberFrom)_65%,var(--amberTo)_112%)] capitalize;
}

button.btn-disabled-grey:disabled,
div.btn-disabled-grey:has(*[aria-grey=true]) /* to support button element with a div wrapper */ {
  @apply bg-[linear-gradient(80deg,#9D9D9D_60%,#C1C1C1_110%)] text-white/100 shadow-[1px_3px_6px_0_#966C0066];
}

.btn-rd-white {
  @apply rounded-full bg-white/100 font-semibold capitalize text-black shadow-md shadow-stone-200 outline-none;
}

.btn-width {
  @apply w-full max-w-sm px-4 md:w-52 md:px-0;
}

.btn-primary {
  @apply bg-gradient-to-r from-amber-v1 to-yellow-200 bg-no-repeat font-semibold text-black transition-colors ease-out hover:from-yellow-400 hover:to-yellow-400 hover:duration-700;
}

.btn-pay {
  @apply rounded bg-[#11B13E] font-semibold text-white/100 hover:bg-[#27d62e] disabled:bg-neutral-200 disabled:text-neutral-500;
}

.clickable-text {
  @apply cursor-pointer text-cyan-v2 underline hover:text-amber-v1;
}

@layer components {
  .web-info {
    background: linear-gradient(182.68deg, #f4de53 0.2%, #d2ad2e 44.44%, #c0941a 72.75%, #bd8f17 97.52%, #b5810d 125.83%, #b07908 138.22%);
    filter: drop-shadow(2px 2px 4px rgba(0, 0, 0, 0.25));
  }
}

.slider-arrow {
  @apply absolute top-1/2 z-20 text-white/100 hover:stroke-2 hover:font-semibold hover:text-amber-v1 disabled:text-neutral-600;
}

/* Needed, because react-slick adds style in the slider wrapper which can only be override by using !important */
.fullscreen-slider-wrap {
  @apply flex min-w-full justify-center !important;
}

.fullscreen-slider .iiz__img {
  max-height: calc(100vh - 96px);
}

.fullscreen-slider,
.fullscreen-slider .slick-track {
  @apply flex items-center;
}

.fullscreen-slider .slick-next,
.fullscreen-slider .slick-prev {
  @apply z-10;
}

.fullscreen-slider .slick-slider {
  @apply min-w-[100vw];
}

.sold-out {
  @apply origin-center rotate-[30deg] rounded bg-error p-4 text-2xl font-semibold uppercase text-white;
}

.sold-out-container {
  @apply absolute left-0 top-0 z-10 h-full w-full bg-black opacity-40;
}

.collapse {
  overflow: hidden;
  transition: 0.3s max-height ease-out;
}

/* for barclay payment */
.bc-form {
  background-image: linear-gradient(30deg, #ffffff00 70%, #ffffff33 70%), linear-gradient(45deg, #ffffff00 75%, #ffffff33 75%),
    linear-gradient(60deg, #ffffff00 80%, #ffffff33 80%);
  background-color: silver;
  box-shadow: 2px 3px 5px 1px #999;
  border: 1px solid #ccc;
}

/*
*  To make slide items equal height:
*  - display track as flex
*  - force slide height to inherit
*  - make items' height full
*/

/* Latest Winners */
#latest-winners .slick-track {
  @apply flex justify-end;
}
#latest-winners .slick-slide {
  @apply !h-[inherit] pb-4 transition-opacity duration-1000 ease-in-out [&:is(.slick-active)]:opacity-100 [&:not(.slick-active)]:opacity-50;
}
#latest-winners .slick-slide > div {
  @apply relative m-0 h-full py-0 pl-2 sm:pl-4;
}

#winner-carousel .slick-slide {
  @apply !w-20;
}

/* Competition Images */
.comp-images-nav .slick-list {
  @apply !mx-0 !p-0;
}
.comp-images-nav .slick-slide {
  @apply m-0 !py-1 px-0;
}
.slick-slider:is(.comp-images, .comp-images-nav) .slick-list {
  @apply !h-[inherit];
}
.slick-slider:is(.comp-images, .comp-images-nav) .slick-slide {
  @apply m-0 border-none p-0;
}
.slick-slider:is(.comp-images, .comp-images-nav) .slick-slide > div {
  @apply relative m-0 flex w-full items-stretch justify-center overflow-clip p-0;
}

/* Competition Slider */
.comp-slider .slick-list {
  @apply !-mx-1 !my-0 !pb-4 !pt-0 md:!-mx-2;
}
.comp-slider .slick-track {
  @apply flex;
}
.comp-slider .slick-slide {
  @apply !h-[inherit] min-h-max;
}
.comp-slider .slick-slide > div {
  @apply relative m-0 h-full min-h-max px-1 py-0 md:px-2;
}

/* Competition Item */
@property --ciFrom {
  syntax: '<color>';
  inherits: false;
  initial-value: transparent;
}
@property --ciTo {
  syntax: '<color>';
  inherits: false;
  initial-value: transparent;
}
.bg-comp-item {
  transition: --ciFrom var(--trans-time), --ciTo var(--trans-time), color var(--trans-time);
}
.bg-comp-item:hover {
  --ciFrom: var(--amber-v1);
  --ciTo: var(--amber-v2);
  @apply hover:bg-[radial-gradient(320%_97%_at_102%_95%,var(--ciFrom)_0%,var(--ciTo)_100%)];
}

/* Apple pay button */
apple-pay-button {
  --apple-pay-button-width: 100%;
  --apple-pay-button-height: 40px; /* h-10 */
  --apple-pay-button-border-radius: 25px;
  --apple-pay-button-padding: 6px 0px;
  --apple-pay-button-box-sizing: border-box;
}
/* apple-pay-button::part(wrapper) {
  @apply px-4;
} */
apple-pay-button::part(btn) {
  /* @apply btn-rd-cyan btn-hover-cyan btn-disabled-grey; */
  @apply bg-transparent;
}
apple-pay-button::part(text) {
  @apply w-full font-['Poppins',-apple-system] text-base font-[650] tracking-normal;
}

/* Google pay button */
.gpay-card-info-animation-container,
.gpay-card-info-placeholder-container,
.gpay-card-info-iframe {
  display: none !important;
  opacity: 0 !important;
  background: transparent !important;
  color: transparent !important;
  height: 0 !important;
  width: 0 !important;
}

/* WebInfo */
.animate-webinfo {
  animation: webinfo 3s ease-in-out infinite;
}
@keyframes webinfo {
  0% {
    color: var(--cyan-v1);
  }
  50% {
    color: var(--amber-v1);
  }
  100% {
    color: var(--cyan-v1);
  }
}
